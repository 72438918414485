import LIBRARIES from '../config';
/**
 * Filter origins of a script tag from skipped origins.
 * @param script - The script tag to check.
 * @returns A boolean value indicating whether the origin is skipped
 */
const filterDomainsToSkip = (script) => {
    const domainsToSkip = [
        ...new Set(LIBRARIES.flatMap((library) => library.domainsToSkip || [])),
    ];
    return (script.origin !== null &&
        !domainsToSkip.some((domain) => { var _a; return (_a = script === null || script === void 0 ? void 0 : script.origin) === null || _a === void 0 ? void 0 : _a.includes(domain); }));
};
/**
 * Detects matching signatures of libraries in loaded scripts.
 * @param scripts - An array of loaded scripts to check.
 * @param detectionFunctions - An object containing detection sub-functions for each library.
 * @returns An object containing the matching signatures and matches for each library.
 */
const detectMatchingSignatures = (scripts, detectionFunctions) => {
    const libraryMatches = Object.fromEntries(LIBRARIES.filter(({ detectionFunction }) => detectionFunction).map(({ name }) => [
        name,
        {
            signatureMatches: 0,
            matches: [],
            moduleMatch: 0,
        },
    ]));
    for (const script of scripts.filter(filterDomainsToSkip)) {
        if (script.content === undefined) {
            continue;
        }
        Object.entries(detectionFunctions).forEach(([key, detectionFunction]) => {
            if (!detectionFunction) {
                return;
            }
            const { signatureMatches, matches, moduleMatch = 0, } = libraryMatches[key];
            libraryMatches[key] = detectionFunction(script, matches, signatureMatches, moduleMatch);
        });
    }
    return libraryMatches;
};
export default detectMatchingSignatures;
