/*
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import React, { useEffect, useState } from 'react';
import { ProgressBar } from '@google-psat/design-system';
import { ReportDisplayType } from '@google-psat/common';
import { I18n } from '@google-psat/i18n';

/**
 * Internal dependencies
 */
import './app.css';
import { Form, OutputDashboard, PageLayout, InfoSection } from './components';

const App = () => {
  const [isAnalysisRunning, setIsAnalysisRunning] = useState(false);
  const [data, setData] = useState(null);
  const [type, setType] = useState(ReportDisplayType.SITE);
  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState('');

  useEffect(() => {
    const bodyTag = document.querySelector('body');

    if (!bodyTag) {
      return;
    }

    bodyTag.style.fontSize = '75%';

    (async () => {
      const res = await fetch('./_locales/en/messages.json');
      const messages = await res.json();
      I18n.initMessages(messages);
      setLoading(false);
    })();
  }, []);

  if (loading) {
    return null;
  }

  return (
    <PageLayout>
      {isAnalysisRunning && (
        <div className="md:h-[60vh] flex flex-col items-center gap-2 justify-center">
          <p>Running Analysis</p>
          <ProgressBar additionalStyles="w-1/3 mx-auto" />
        </div>
      )}

      <div
        className={`h-full flex-1 flex-col ${
          isAnalysisRunning ? 'hidden' : 'flex'
        }`}
      >
        <Form
          setData={setData}
          setIsAnalysisRunning={setIsAnalysisRunning}
          setType={setType}
          setUrl={setUrl}
          url={url}
        />

        {data && (
          <OutputDashboard
            data={data}
            type={type}
            path={new URL(url).hostname}
          />
        )}

        {!data && <InfoSection />}
      </div>
    </PageLayout>
  );
};

export default App;
