/*
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * Internal dependencies
 */
import { API_ROOT } from '../constants/environment';

export const analyzeUrl = async (
  url: string,
  reanalyzeCookies = false,
  reanalyzeTechnologies = false
) => {
  const response = await fetch(
    `${API_ROOT}?` +
      new URLSearchParams({
        url,
        reanalyzeCookies: reanalyzeCookies ? 'yes' : 'no',
        reanalyzeTechnologies: reanalyzeTechnologies ? 'yes' : 'no',
      }),
    {
      method: 'GET',
    }
  );

  if (!response.ok) {
    throw new Error('Failed to analyze URL');
  }

  const data = await response.json();

  if ('error' in data) {
    throw new Error(data.error);
  }

  return data;
};
