import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/*
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * External dependencies.
 */
import React, { memo } from 'react';
import { CookiesLandingWrapper, COLOR_MAP, ProgressBar, } from '@google-psat/design-system';
import { I18n } from '@google-psat/i18n';
/**
 * Internal dependencies.
 */
import { useLibraryDetection, useLibraryDetectionContext } from '../../core';
import LIBRARIES from '../../config';
const LibraryDetection = memo(function LibraryDetection() {
    useLibraryDetection();
    const { libraryMatches, showLoader, isCurrentTabLoading, errorOccured } = useLibraryDetectionContext(({ state }) => ({
        libraryMatches: state.libraryMatches,
        errorOccured: state.errorOccured,
        showLoader: state.showLoader,
        isCurrentTabLoading: state.isCurrentTabLoading,
    }));
    const names = Object.keys(libraryMatches);
    const detectedLibraryNames = names.filter((name) => {
        var _a, _b, _c, _d;
        return ((_b = (_a = libraryMatches[name]) === null || _a === void 0 ? void 0 : _a.matches) === null || _b === void 0 ? void 0 : _b.length) ||
            ((_d = (_c = libraryMatches[name]) === null || _c === void 0 ? void 0 : _c.domQuerymatches) === null || _d === void 0 ? void 0 : _d.length);
    });
    const dataMapping = [
        {
            title: I18n.getMessage('knownBreakages'),
            count: Number(detectedLibraryNames.length),
            data: detectedLibraryNames.map((name) => {
                var _a, _b, _c, _d;
                return ({
                    count: ((_b = (_a = libraryMatches[name]) === null || _a === void 0 ? void 0 : _a.matches) === null || _b === void 0 ? void 0 : _b.length) ||
                        ((_d = (_c = libraryMatches[name]) === null || _c === void 0 ? void 0 : _c.domQuerymatches) === null || _d === void 0 ? void 0 : _d.length) ||
                        0,
                    color: COLOR_MAP[name].color,
                });
            }),
        },
    ];
    const result = !errorOccured && detectedLibraryNames.length > 0 ? (_jsx(_Fragment, { children: LIBRARIES.map((library) => {
            var _a, _b;
            const Component = library.component;
            const matches = libraryMatches && libraryMatches[library.name]
                ? (_a = libraryMatches[library.name]) === null || _a === void 0 ? void 0 : _a.matches
                : [];
            const domQueryMatches = libraryMatches && libraryMatches[library.name]
                ? (_b = libraryMatches[library.name]) === null || _b === void 0 ? void 0 : _b.domQuerymatches
                : null;
            return (_jsx(Component, { matches: matches, domQueryMatches: domQueryMatches }, library.name));
        }) })) : !errorOccured ? (_jsx("p", { className: "text-center dark:text-bright-gray", children: I18n.getMessage('noLibraries') })) : (_jsx("p", { className: "text-center dark:text-bright-gray", children: I18n.getMessage('errorOccured') }));
    return (_jsx(CookiesLandingWrapper, { dataMapping: dataMapping, testId: "library-detection", description: "", children: !errorOccured && showLoader ? (_jsxs(_Fragment, { children: [_jsx(ProgressBar, { additionalStyles: "w-1/3 mx-auto h-full" }), _jsx("p", { className: "text-center dark:text-bright-gray", children: isCurrentTabLoading
                        ? I18n.getMessage('waitingPageLoad')
                        : I18n.getMessage('checkingLibraries') })] })) : (_jsx("div", { className: "divide-y divide-hex-gray", children: result })) }));
});
export default LibraryDetection;
