import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/*
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * External dependencies
 */
import { useMemo, useState } from 'react';
import { Resizable } from 're-resizable';
import {} from '@google-psat/common';
import { CookieTable, CookieDetails } from '@google-psat/design-system';
/**
 * Internal dependencies
 */
import useCookieListing from '../../../../../hooks/useCookieListing';
import { useContentStore } from '../../../stateProviders/contentStore';
const CookiesListing = ({ selectedFrameUrl, selectedSite, }) => {
    const [selectedFrameCookie, setSelectedFrameCookie] = useState(null);
    const { tabCookies, path } = useContentStore(({ state }) => ({
        tabCookies: state.tabCookies,
        path: state.path,
    }));
    const cookies = useMemo(() => Object.values(tabCookies).filter((cookie) => cookie.frameUrls.includes(selectedFrameUrl)), [tabCookies, selectedFrameUrl]);
    const memoTabCookies = useMemo(() => Object.values(tabCookies), [tabCookies]);
    const { tableColumns, filters, searchKeys, tablePersistentSettingsKey, isSidebarOpen, } = useCookieListing(memoTabCookies, selectedFrameUrl, 'cookiesListing', selectedSite);
    //@ts-ignore -- PSAT_EXTENSTION is added only when the report is downloaded from the extension. Since optional chaining is done it will return false if it doesnt exist.
    const isCLI = globalThis?.PSAT_EXTENSION ? false : true;
    return (_jsxs("div", { className: "w-full h-full flex flex-col", children: [_jsx(Resizable, { defaultSize: {
                    width: '100%',
                    height: '80%',
                }, minHeight: "6%", maxHeight: "95%", enable: {
                    bottom: true,
                }, className: "h-full flex", children: _jsx(CookieTable, { data: cookies, tableColumns: tableColumns, tableFilters: filters, tableSearchKeys: searchKeys, tablePersistentSettingsKey: tablePersistentSettingsKey, selectedFrame: selectedFrameUrl, selectedFrameCookie: selectedFrameCookie, setSelectedFrameCookie: setSelectedFrameCookie, isFiltersSidebarOpen: isSidebarOpen, isCLI: isCLI, hostname: path }) }), _jsx(CookieDetails, { isUsingCDP: true, selectedFrameCookie: selectedFrameCookie })] }));
};
export default CookiesListing;
