import { jsx as _jsx } from "react/jsx-runtime";
const FeatureList = ({ matches }) => {
    if (!matches.length) {
        return null;
    }
    return (_jsx("ul", { className: "mt-2 list-disc pl-3 dark:text-bright-gray", children: matches.map(({ feature }) => {
            if (feature.type !== 'link') {
                return null;
            }
            return (_jsx("li", { children: _jsx("a", { className: "text-bright-navy-blue dark:text-jordy-blue", target: "_blank", href: feature.url, rel: "noreferrer", children: feature.text }) }, feature.text));
        }) }));
};
export default FeatureList;
