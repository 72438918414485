/*
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * Match toMatch key with the last key in the chained key.
 * @param key chained key to match with.
 * @param toMatch String to match.
 * @returns Boolean.
 */
const matchKey = (key: string, toMatch: string) => {
  const keys = key.split('#');
  const length = keys.length;

  return keys[length - 1] === toMatch;
};

export default matchKey;
