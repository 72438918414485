/**
 * Sums up two library detection objects.
 * @param obj1 - The first library detection object.
 * @param obj2 - The second library detection object.
 * @returns The sum of the two library detection object.
 */
const sumUpDetectionResults = (obj1, obj2) => {
    var _a, _b, _c, _d;
    const resultObj = Object.assign({}, obj1);
    const libraryKeys = Object.keys(obj1);
    for (let i = 0; i < libraryKeys.length; i++) {
        const key = libraryKeys[i];
        const resultObjMatches = resultObj[key] ? (_a = resultObj[key].matches) !== null && _a !== void 0 ? _a : [] : [];
        const obj2Matches = obj2[key] ? (_b = obj2[key].matches) !== null && _b !== void 0 ? _b : [] : [];
        for (let j = 0; j < resultObjMatches.length; j++) {
            const featureText = resultObjMatches[j].feature.text;
            const sameFeatureInOtherObject = obj2Matches.find((match) => match.feature.text === featureText);
            if (!sameFeatureInOtherObject) {
                continue;
            }
            const sameFeatureInOtherObjectIndex = obj2Matches.findIndex((match) => match.feature.text === featureText);
            obj2Matches.splice(sameFeatureInOtherObjectIndex, 1);
            resultObjMatches[j].subItems.items = [
                ...resultObjMatches[j].subItems.items,
                ...sameFeatureInOtherObject.subItems.items,
            ];
        }
        resultObj[key].matches = [...resultObjMatches, ...obj2Matches];
        const resultObjSignature = resultObj[key]
            ? (_c = resultObj[key].signatureMatches) !== null && _c !== void 0 ? _c : 0
            : 0;
        const obj2Signature = obj2[key] ? (_d = obj2[key].signatureMatches) !== null && _d !== void 0 ? _d : 0 : 0;
        resultObj[key].signatureMatches = resultObjSignature + obj2Signature;
        if (resultObj[key].moduleMatch && obj2[key].moduleMatch) {
            resultObj[key].moduleMatch =
                resultObj[key].moduleMatch +
                    obj2[key].moduleMatch;
        }
    }
    return resultObj;
};
export default sumUpDetectionResults;
