/*
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getValueByKey = (keys: string, data: any) => {
  const _keys = keys.split('.');
  let value = data; // eslint-disable-line @typescript-eslint/no-explicit-any -- Value can of any

  _keys.forEach((key) => {
    value = value?.[key];
  });

  return value;
};

export default getValueByKey;
