/*
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import React from 'react';
import { QUICK_LINKS } from '@google-psat/design-system';
import { I18n } from '@google-psat/i18n';

const InfoSection = () => {
  return (
    <div className="max-w-[960px] min-w-[344px] mx-4 mt-24 mb-4 md:mx-auto">
      <section className="flex gap-2 flex-col-reverse md:flex-row">
        <div>
          <h1 className="text-4xl font-normal mb-8">
            Analyze your site for third-party cookies
          </h1>
          <p className="text-xs uppercase my-3">Check out</p>
          <div className="flex flex-col font-normal text-sm space-y-2">
            {QUICK_LINKS.map((item) => (
              <div key={item.link}>
                <a
                  className="text-bright-navy-blue dark:text-jordy-blue hover:underline"
                  href={item.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  {I18n.getMessage(item.title)}
                </a>
              </div>
            ))}
          </div>
        </div>
        <img
          src="https://www.gstatic.com/pagespeed/insights/ui/img/graphic-home-hero.svg"
          width="240px"
          height="240px"
          alt=""
          className="mx-auto"
        />
      </section>
    </div>
  );
};

export default InfoSection;
