import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
/*
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*
 * External dependencies
 */
import React from 'react';
import { LIBRARIES } from '@google-psat/library-detection';
import { I18n } from '@google-psat/i18n';
import { COLOR_MAP, CookiesLandingWrapper } from '@google-psat/design-system';
const KnownBreakages = ({ libraryMatches, libraryMatchesUrlCount, }) => {
    const names = Object.keys(libraryMatches);
    const detectedLibraryNames = names.filter((name) => libraryMatches[name]?.matches?.length ||
        libraryMatches[name]?.domQuerymatches?.length);
    const dataMapping = [
        {
            title: I18n.getMessage('knownBreakages'),
            count: Number(detectedLibraryNames.length),
            data: detectedLibraryNames.map((name) => ({
                count: libraryMatches[name]?.matches?.length ||
                    libraryMatches[name]?.domQuerymatches?.length ||
                    0,
                color: COLOR_MAP[name].color,
            })),
        },
    ];
    const result = detectedLibraryNames.length > 0 ? (_jsx(_Fragment, { children: LIBRARIES.map((library) => {
            const Component = library.component;
            const matches = libraryMatches && libraryMatches[library.name]
                ? libraryMatches[library.name]?.matches
                : [];
            const domQueryMatches = libraryMatches && libraryMatches[library.name]
                ? libraryMatches[library.name]
                    ?.domQuerymatches
                : null;
            return (_jsx(Component, { matches: matches, domQueryMatches: domQueryMatches, urlCount: libraryMatchesUrlCount?.[library.name] }, library.name));
        }) })) : (_jsx("p", { className: "text-center dark:text-bright-gray", children: I18n.getMessage('noLibraries') }));
    return (_jsx(CookiesLandingWrapper, { dataMapping: dataMapping, testId: "library-detection", description: "", children: _jsx("div", { className: "divide-y divide-hex-gray", children: result }) }));
};
export default KnownBreakages;
