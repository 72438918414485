/*
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import React, { useCallback, useState } from 'react';
import { Button } from '@google-psat/design-system';
import { ReportDisplayType } from '@google-psat/common';

/**
 * Internal dependencies
 */
import { analyzeUrl } from '../../service/api';
import { validateUrl } from '../../utils';

interface FormProps {
  setData: React.Dispatch<React.SetStateAction<any>>;
  setIsAnalysisRunning: React.Dispatch<React.SetStateAction<boolean>>;
  setType: React.Dispatch<React.SetStateAction<ReportDisplayType>>;
  url: string;
  setUrl: React.Dispatch<React.SetStateAction<string>>;
}

const Form = ({
  setData,
  setIsAnalysisRunning,
  setType,
  url,
  setUrl,
}: FormProps) => {
  const [reAnalyzeCookies, setReAnalyzeCookies] = useState(false);
  const [reAnalyzeTechnologies, setReAnalyzeTechnologies] = useState(false);
  const [invalidUrl, setInvalidUrl] = useState(false);
  const [apiError, setApiError] = useState('');

  const handleFormSubmission = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setData(null);

      setInvalidUrl(false);
      setApiError('');

      if (!validateUrl(url)) {
        setInvalidUrl(true);

        return;
      }

      setType(
        url.endsWith('.xml')
          ? ReportDisplayType.SITEMAP
          : ReportDisplayType.SITE
      );

      try {
        setIsAnalysisRunning(true);
        const data = await analyzeUrl(
          url,
          reAnalyzeCookies,
          reAnalyzeTechnologies
        );

        setData(data);
      } catch (error: any) {
        setApiError(error.message);
      } finally {
        setIsAnalysisRunning(false);
      }
    },
    [
      reAnalyzeCookies,
      reAnalyzeTechnologies,
      setData,
      setIsAnalysisRunning,
      setType,
      url,
    ]
  );

  return (
    <form
      className="max-w-[960px] mx-auto flex min-w-[300px] gap-4 w-full"
      onSubmit={handleFormSubmission}
    >
      <div className="flex-grow">
        <label className="block w-full">
          <input
            autoFocus
            onChange={(e) => setUrl(e.target.value)}
            type="text"
            value={url}
            className="w-full h-12 px-4 text-base border border-crayola-blue rounded invalid:border-red-600"
            placeholder="Enter a web page or sitemap URL"
            required
          />
        </label>
        <div className="flex gap-4">
          <label className="flex items-center text-xs mt-2 text-granite-gray">
            <input
              type="checkbox"
              className="mr-2 cursor-pointer"
              checked={reAnalyzeCookies}
              onChange={(e) => setReAnalyzeCookies(e.target.checked)}
            />
            Reanalyze Cookies
          </label>
          <label className="flex items-center text-xs mt-2 text-granite-gray">
            <input
              type="checkbox"
              className="mr-2 cursor-pointer"
              checked={reAnalyzeTechnologies}
              onChange={(e) => setReAnalyzeTechnologies(e.target.checked)}
            />
            Reanalyze Technologies
          </label>
        </div>
        {invalidUrl && (
          <p className="pl-4 text-sm mt-1 text-red-600">Enter a valid URL</p>
        )}
        {apiError && (
          <p className="pl-4 text-sm mt-1 text-red-600">{apiError}</p>
        )}
      </div>
      <Button
        type="submit"
        text="Analyze"
        variant="large"
        extraClasses="h-12 px-5 bg-crayola-blue text-sm"
      />
    </form>
  );
};

export default Form;
