import { jsx as _jsx } from "react/jsx-runtime";
/*
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * External dependencies.
 */
import { useState } from 'react';
import { SidebarProvider } from '@google-psat/design-system';
/**
 * Internal dependencies.
 */
import sidebarData from './sidebarData';
import Layout from './layout';
const SiteMapReport = ({ landingPageCookies, completeJson, path, libraryMatches, }) => {
    const [data, setData] = useState(sidebarData);
    return (_jsx(SidebarProvider, { data: data, children: _jsx(Layout, { landingPageCookies: landingPageCookies, completeJson: completeJson, sidebarData: data, setSidebarData: setData, path: path, libraryMatches: libraryMatches }) }));
};
export default SiteMapReport;
