/*
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * External dependencies
 */
import React, { useEffect, useState } from 'react';
import {
  ReportDisplayType,
  type CompleteJson,
  type CookieFrameStorageType,
  type LibraryData,
  type TechnologyData,
} from '@google-psat/common';
import {
  SiteMapReport,
  SiteReport,
  extractCookies,
  extractReportData,
} from '@google-psat/report';

interface OutputDashboardProps {
  data: any;
  path: string;
  type: ReportDisplayType;
}

const OutputDashboard = ({ data, type, path }: OutputDashboardProps) => {
  const [cookies, setCookies] = useState<CookieFrameStorageType>({});
  const [landingPageCookies, setLandingPageCookies] =
    useState<CookieFrameStorageType>({});
  const [libraryMatches, setLibraryMatches] = useState<{
    [key: string]: LibraryData;
  } | null>(null);
  const [technologies, setTechnologies] = useState<TechnologyData[]>([]);
  const [completeJsonReport, setCompleteJsonReport] = useState<
    CompleteJson[] | null
  >(null);

  useEffect(() => {
    const _data = type === ReportDisplayType.SITEMAP ? data : [data];
    setCompleteJsonReport(_data);

    if (type === ReportDisplayType.SITEMAP) {
      const extractedData = extractReportData(_data);

      setLandingPageCookies(extractedData.landingPageCookies);
      setLibraryMatches(extractedData.consolidatedLibraryMatches);
    } else {
      const extractedData = extractCookies(data.cookieData, data.pageUrl, true);

      setCookies(extractedData);
      setTechnologies(data.technologyData);
      setLibraryMatches({
        [data.pageUrl]: data.libraryMatches,
      });
    }

    // @ts-ignore
    globalThis.PSAT_DATA = {
      showHeader: false,
      hideDownloadButton: true,
    };
  }, [data, type]);

  if (!data) {
    return null;
  }

  return (
    <div className="mt-5 min-w-[300px] border-t border-hex-gray overflow-hidden flex-1">
      <div className="w-full h-full flex">
        {type === ReportDisplayType.SITEMAP ? (
          <SiteMapReport
            landingPageCookies={landingPageCookies}
            completeJson={completeJsonReport}
            path={path}
            libraryMatches={libraryMatches}
          />
        ) : (
          <SiteReport
            completeJson={completeJsonReport}
            cookies={cookies}
            technologies={technologies}
            selectedSite={''}
            path={path}
            libraryMatches={
              libraryMatches
                ? libraryMatches[Object.keys(libraryMatches ?? {})[0]]
                : null
            }
          />
        )}
      </div>
    </div>
  );
};

export default OutputDashboard;
