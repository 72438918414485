/**
 * Filters the input resources based on their type and URL.
 * @param inputResources - The array of input resources to filter.
 * @returns The filtered array of resources.
 */
const filterResources = (inputResources) => {
    return inputResources
        .filter((resource) => resource.type !== undefined &&
        ['script', 'document'].includes(resource.type))
        .filter((i) => !i.url.includes('chrome-extension://') && !i.url.includes('debugger://'));
};
export default filterResources;
