import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/*
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * External dependencies
 */
import { useCallback } from 'react';
import { getLegendDescription, } from '@google-psat/common';
import { I18n } from '@google-psat/i18n';
import { prepareCookieStatsComponents, prepareCookiesCount, LEGEND_DESCRIPTION, useFiltersMapping, CookiesLandingWrapper, MatrixContainer, } from '@google-psat/design-system';
const BlockedCookiesSection = ({ tabCookies, cookiesWithIssues, tabFrames, }) => {
    const { selectedItemUpdater, multiSelectItemUpdater } = useFiltersMapping(tabFrames || {});
    const cookieStats = prepareCookiesCount(tabCookies);
    const cookiesStatsComponents = prepareCookieStatsComponents(cookieStats);
    const blockedCookieDataMapping = [
        {
            title: I18n.getMessage('blockedCookies'),
            count: cookieStats.blockedCookies.total,
            data: cookiesStatsComponents.blocked,
            onClick: cookieStats.blockedCookies.total > 0
                ? () => selectedItemUpdater(I18n.getMessage('selectAll'), 'blockedReasons')
                : null,
        },
    ];
    const selectTabFrame = useCallback((blockedReason) => {
        const cookie = Object.values(tabCookies || {}).find((_cookie) => {
            // @ts-ignore - blockedReasons is a string array
            if (_cookie.blockedReasons?.includes(blockedReason)) {
                return true;
            }
            return false;
        });
        return cookie?.pageUrl || '';
    }, [tabCookies]);
    const dataComponents = cookiesStatsComponents.blockedCookiesLegend.map((component) => {
        const legendDescription = LEGEND_DESCRIPTION[component.label] || '';
        return {
            ...component,
            description: getLegendDescription(legendDescription),
            title: component.label,
            containerClasses: '',
            onClick: (title) => selectedItemUpdater(title, 'blockedReasons', selectTabFrame(title)),
        };
    });
    const blockedCookiesStats = prepareCookiesCount(cookiesWithIssues);
    const blockedCookiesStatsComponents = prepareCookieStatsComponents(blockedCookiesStats);
    const blockedDataComponents = blockedCookiesStatsComponents.legend.map((component) => {
        const legendDescription = LEGEND_DESCRIPTION[component.descriptionKey || ''];
        return {
            ...component,
            description: getLegendDescription(legendDescription),
            title: component.label,
            containerClasses: '',
            onClick: (title) => {
                multiSelectItemUpdater({
                    blockedReasons: [I18n.getMessage('selectAll')],
                    'analytics.category': [title],
                });
            },
        };
    });
    const description = blockedCookiesStats.blockedCookies.total === 0
        ? 'No cookies were blocked by the browser.'
        : '';
    return (_jsx(CookiesLandingWrapper, { description: description, dataMapping: blockedCookieDataMapping, testId: "blocked-cookies-insights", children: dataComponents.length > 0 && (_jsxs(_Fragment, { children: [_jsx(MatrixContainer, { title: I18n.getMessage('blockedCookies'), matrixData: dataComponents, infoIconTitle: I18n.getMessage('blockedReasonsNote') }), 
                //@ts-ignore -- PSAT_EXTENSTION is added only when the report is downloaded from the extension. Since optional chaining is done it will return false if it doesnt exist.
                !globalThis?.PSAT_EXTENSION && (_jsx("div", { className: "flex flex-col mt-8", children: _jsx("div", { className: "pt-4", children: _jsx(MatrixContainer, { matrixData: blockedDataComponents, allowExpand: false }) }) }))] })) }));
};
export default BlockedCookiesSection;
