var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/*
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * External dependencies.
 */
import { useCallback, useEffect, useRef } from 'react';
import { executeTaskInDevToolWorker, LIBRARY_DETECTION_WORKER_TASK, } from '@google-psat/common';
import { diff } from 'deep-object-diff';
/**
 * Internal dependencies.
 */
import { getNetworkResourcesWithContent, getResourcesWithContent, } from '../../utils';
import { sumUpDetectionResults, useLibraryDetectionContext } from '..';
import LIBRARIES from '../../config';
// The delay after the page load, because some scripts arrive right after the page load.
const LOADING_DELAY = 2000;
/**
 * Custom hook that handles the library detection logic.
 */
const useLibraryDetection = () => {
    const { isCurrentTabLoading, isInitialDataUpdated, setIsInitialDataUpdated, loadedBefore, showLoader, setLibraryMatches, setLoadedBeforeState, setShowLoader, tabId, } = useLibraryDetectionContext(({ state, actions }) => ({
        isCurrentTabLoading: state.isCurrentTabLoading,
        isInitialDataUpdated: state.isInitialDataUpdated,
        setIsInitialDataUpdated: actions.setIsInitialDataUpdated,
        loadedBefore: state.loadedBefore,
        showLoader: state.showLoader,
        setLoadedBeforeState: actions.setLoadedBeforeState,
        setLibraryMatches: actions.setLibraryMatches,
        setShowLoader: actions.setShowLoader,
        tabId: state.tabId,
    }));
    const timeout = useRef(0);
    /**
     * Callback function used as a listener for resource changes.
     * It detects library matches in the updated resources and updates the library matches state accordingly.
     * @param resource - The updated resource for which library detection needs to be performed.
     */
    const listenerCallback = useCallback((resource) => __awaiter(void 0, void 0, void 0, function* () {
        const resourcesWithContent = yield getResourcesWithContent([resource]);
        if (resourcesWithContent.length === 0) {
            return;
        }
        executeTaskInDevToolWorker(LIBRARY_DETECTION_WORKER_TASK.DETECT_SIGNATURE_MATCHING, resourcesWithContent, (realtimeComputationResult) => {
            const hasResults = Object.entries(realtimeComputationResult).find(([, result]) => { var _a; return (_a = result === null || result === void 0 ? void 0 : result.matches) === null || _a === void 0 ? void 0 : _a.length; });
            if (hasResults) {
                setLibraryMatches((matches) => {
                    const data = sumUpDetectionResults(matches, realtimeComputationResult);
                    const diffedLibraryMatches = diff(data, matches);
                    if (Object.keys(diffedLibraryMatches).length > 0) {
                        return data;
                    }
                    return matches;
                });
            }
        });
    }), [setLibraryMatches]);
    const removeListener = useCallback(() => {
        chrome.devtools.inspectedWindow.onResourceAdded.removeListener(listenerCallback);
    }, [listenerCallback]);
    const attachListener = useCallback(() => {
        removeListener();
        chrome.devtools.inspectedWindow.onResourceAdded.addListener(listenerCallback);
    }, [listenerCallback, removeListener]);
    const updateInitialData = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (isInitialDataUpdated) {
            return;
        }
        setIsInitialDataUpdated(true);
        //  chrome.devtools.inspectedWindow.getResources updates whenever new items are added.
        const scripts = yield getNetworkResourcesWithContent();
        const domQueryMatches = {};
        yield Promise.all(LIBRARIES.map((_a) => __awaiter(void 0, [_a], void 0, function* ({ name, domQueryFunction }) {
            if (domQueryFunction) {
                try {
                    const [queryResult] = yield chrome.scripting.executeScript({
                        target: { tabId: tabId },
                        func: domQueryFunction,
                    });
                    domQueryMatches[name] = {
                        domQuerymatches: queryResult === null || queryResult === void 0 ? void 0 : queryResult.result,
                    };
                }
                catch (error) {
                    // Fail silently
                    // eslint-disable-next-line no-console
                    console.log(error);
                }
            }
        })));
        executeTaskInDevToolWorker(LIBRARY_DETECTION_WORKER_TASK.DETECT_SIGNATURE_MATCHING, scripts, (detectedMatchingSignatures) => {
            const data = Object.assign(Object.assign({}, detectedMatchingSignatures), domQueryMatches);
            setLibraryMatches(data);
            attachListener();
            setShowLoader(false);
        });
    }), [
        setLibraryMatches,
        attachListener,
        setShowLoader,
        setIsInitialDataUpdated,
        tabId,
        isInitialDataUpdated,
    ]);
    useEffect(() => {
        if (showLoader) {
            removeListener();
        }
    }, [showLoader, removeListener]);
    // Get the initial data and listen to new resources.
    useEffect(() => {
        // Show loader while page is loading.
        if (isCurrentTabLoading) {
            return;
        }
        // If the user visits the landing page for the first time,
        // Or after the tab has updated (reloaded or changed) show loader for few seconds.
        if (!loadedBefore) {
            // Since this useEffect has multiple depedency, it may be called multiple times.
            // We want to ensure that we show the loader only once.
            if (!timeout.current) {
                timeout.current = setTimeout(() => {
                    updateInitialData();
                    setLoadedBeforeState(true);
                    timeout.current = 0;
                }, LOADING_DELAY);
            }
        }
        else {
            // When the user revisits the landing page we do not need to show loader.
            updateInitialData();
        }
    }, [
        isCurrentTabLoading,
        loadedBefore,
        setLoadedBeforeState,
        updateInitialData,
    ]);
    // CLeanup on component unmount.
    useEffect(() => {
        return () => {
            removeListener();
            if (timeout.current) {
                clearTimeout(timeout.current);
                timeout.current = 0;
            }
        };
    }, [removeListener]);
};
export default useLibraryDetection;
