import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/*
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * External dependencies
 */
import { useMemo, useState } from 'react';
import { Resizable } from 're-resizable';
import { noop } from '@google-psat/common';
import { I18n } from '@google-psat/i18n';
import { Table, TableProvider, } from '@google-psat/design-system';
/**
 * Internal dependencies
 */
import { useContentStore } from '../../stateProviders/contentStore';
const Technologies = ({ selectedSite }) => {
    const data = useContentStore(({ state }) => state.technologies || []);
    const [selectedRow, setSelectedRow] = useState();
    const tableColumns = useMemo(() => [
        {
            header: I18n.getMessage('name'),
            accessorKey: 'name',
            cell: (info) => info,
            enableHiding: false,
        },
        {
            header: I18n.getMessage('description'),
            accessorKey: 'description',
            cell: (info) => info,
        },
        {
            header: I18n.getMessage('confidence'),
            accessorKey: 'confidence',
            cell: (info) => (_jsx("span", { className: "w-full flex justify-center", children: info + '%' })),
        },
        {
            header: I18n.getMessage('website'),
            accessorKey: 'website',
            cell: (info) => info,
        },
        {
            header: I18n.getMessage('category'),
            accessorKey: 'categories',
            cell: (info) => info.map((i) => i.name).join(' | '),
            sortingComparator: (a, b) => {
                const aCategories = a
                    ?.map((i) => i.name)
                    .join(' | ') || '';
                const bCategories = b
                    ?.map((i) => i.name)
                    .join(' | ') || '';
                return aCategories.localeCompare(bCategories);
            },
        },
    ], []);
    const filters = useMemo(() => ({}), []);
    const searchKeys = useMemo(() => ['name', 'website'], []);
    const tablePersistentSettingsKey = useMemo(() => {
        if (selectedSite) {
            return `technologyListing#${selectedSite}`;
        }
        return 'technologyListing';
    }, [selectedSite]);
    return (_jsxs("div", { className: "w-full h-full text-outer-space-crayola border-x border-american-silver dark:border-quartz flex flex-col", children: [_jsx(Resizable, { defaultSize: {
                    width: '100%',
                    height: '80%',
                }, minHeight: "6%", maxHeight: "95%", enable: {
                    top: false,
                    right: false,
                    bottom: true,
                    left: false,
                }, className: "h-full flex", children: _jsx(TableProvider, { data: data, tableColumns: tableColumns, tableFilterData: filters, tableSearchKeys: searchKeys, tablePersistentSettingsKey: tablePersistentSettingsKey, onRowClick: (row) => {
                        setSelectedRow(row);
                    }, onRowContextMenu: noop, getRowObjectKey: (row) => {
                        return row.originalData.slug;
                    }, children: _jsx(Table, { hideFiltering: true, selectedKey: selectedRow?.slug }) }) }), _jsx("div", { className: "flex-1 border border-gray-300 dark:border-quartz shadow h-full min-w-[10rem]", children: selectedRow ? (_jsxs("div", { className: "text-xs py-1 px-1.5", children: [selectedRow.name && (_jsxs(_Fragment, { children: [_jsx("p", { className: "font-bold text-granite-gray dark:text-manatee mb-1 text-semibold flex items-center", children: _jsx("span", { children: I18n.getMessage('technologyDetails') }) }), _jsx("p", { className: "mb-4 break-words text-outer-space-crayola dark:text-bright-gray", children: selectedRow.name })] })), _jsxs(_Fragment, { children: [_jsx("p", { className: "font-bold text-granite-gray dark:text-manatee mb-1", children: I18n.getMessage('description') }), _jsx("p", { className: "text-outer-space-crayola dark:text-bright-gray", children: selectedRow?.description || I18n.getMessage('noDescription') })] })] })) : (_jsx("div", { className: "h-full p-8 flex items-center", children: _jsx("p", { className: "text-lg w-full font-bold text-granite-gray dark:text-manatee text-center", children: I18n.getMessage('selectRowToPreview') }) })) })] }));
};
export default Technologies;
