import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/*
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * External dependencies
 */
import { useCallback, useMemo } from 'react';
import { getLegendDescription, } from '@google-psat/common';
import { I18n } from '@google-psat/i18n';
import { prepareCookieStatsComponents, prepareCookiesCount, useFiltersMapping, CookiesLandingWrapper, InfoIcon, MatrixContainer, LEGEND_DESCRIPTION, } from '@google-psat/design-system';
const ExemptedCookiesSection = ({ tabCookies, tabFrames, }) => {
    const { selectedItemUpdater } = useFiltersMapping(tabFrames || {});
    const cookieStats = useMemo(() => prepareCookiesCount(tabCookies), [tabCookies]);
    const cookiesStatsComponents = prepareCookieStatsComponents(cookieStats);
    const selectTabFrame = useCallback((exemptionReason) => {
        const cookie = Object.values(tabCookies || {}).find((_cookie) => {
            // @ts-ignore - exemptionReasons is a string array
            if (_cookie.exemptionReason === exemptionReason) {
                return true;
            }
            return false;
        });
        return cookie?.pageUrl || '';
    }, [tabCookies]);
    const dataComponents = cookiesStatsComponents.exemptedCookiesLegend.map((component) => {
        const legendDescription = LEGEND_DESCRIPTION[component.label] || '';
        return {
            ...component,
            description: getLegendDescription(legendDescription),
            title: component.label,
            containerClasses: '',
            onClick: (title) => {
                selectedItemUpdater(title, 'exemptionReason', selectTabFrame(title));
            },
        };
    });
    const exemptedCookiesDataMapping = [
        {
            title: I18n.getMessage('exemptedCookies'),
            count: cookieStats.exemptedCookies.total,
            data: cookiesStatsComponents.exempted,
            onClick: cookieStats.exemptedCookies.total > 0
                ? () => selectedItemUpdater(I18n.getMessage('selectAll'), 'exemptionReason')
                : null,
        },
    ];
    const description = !cookieStats.exemptedCookies.total ? (_jsxs("div", { className: "flex gap-1 justify-center items-center", children: [I18n.getMessage('noCookiesExempted'), _jsx("span", { title: I18n.getMessage('exemptedCookiesIn3PCD'), children: _jsx(InfoIcon, { className: "fill-granite-gray" }) })] })) : ('');
    return (_jsx(CookiesLandingWrapper, { description: description, dataMapping: exemptedCookiesDataMapping, children: dataComponents.length > 0 && (_jsx(MatrixContainer, { title: I18n.getMessage('exemptionReasons'), matrixData: dataComponents, infoIconTitle: I18n.getMessage('exemptionReasonsNote') })) }));
};
export default ExemptedCookiesSection;
