import { jsx as _jsx } from "react/jsx-runtime";
/*
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * External dependencies
 */
import { useState } from 'react';
import { noop, } from '@google-psat/common';
import { SidebarProvider } from '@google-psat/design-system';
/**
 * Internal dependencies.
 */
import { Provider as ContentStoreProvider } from './stateProviders/contentStore';
import Layout from './components/layout';
import Tabs from './tabs';
const SiteReport = ({ cookies, technologies, completeJson, selectedSite, path, libraryMatches, query = '', clearQuery = noop, }) => {
    const [data, setData] = useState(Tabs);
    return (_jsx(ContentStoreProvider, { cookies: cookies, technologies: technologies, completeJson: completeJson, libraryMatches: libraryMatches, path: path, children: _jsx(SidebarProvider, { data: data, children: _jsx(Layout, { selectedSite: selectedSite, setSidebarData: setData, query: query, clearQuery: clearQuery }) }) }));
};
export default SiteReport;
