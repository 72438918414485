/*
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Internal dependencies.
 */
import { FramesWithCookies, TabCookies, TabFrames } from '../cookies.types';
import filterCookiesByFrame from './filterCookiesByFrame';

const filterFramesWithCookies = (
  tabCookies: TabCookies | null,
  tabFrames: TabFrames | null
): FramesWithCookies => {
  const framesWithCookies: FramesWithCookies = {};

  if (!tabCookies || !tabFrames) {
    return framesWithCookies;
  }

  Object.keys(tabFrames).forEach((frameUrl) => {
    const cookies = filterCookiesByFrame(tabCookies, tabFrames, frameUrl);

    if (cookies.length) {
      framesWithCookies[frameUrl] = tabFrames[frameUrl];
    }
  });

  return framesWithCookies;
};

export default filterFramesWithCookies;
