var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getInlineScriptContent, filterResources } from '../core';
/**
 * This function returns the Resources of the website in the inspectedWindow
 * @param resources Resources
 * @returns Resources with content.
 */
export const getResourcesWithContent = (resources) => __awaiter(void 0, void 0, void 0, function* () {
    const filteredResources = filterResources(resources);
    const contentPromises = [];
    const resourcesWithOutContent = [];
    filteredResources.forEach((resource) => {
        contentPromises.push(new Promise((resolve) => resource.getContent((content) => resolve(content))));
        resourcesWithOutContent.push({
            origin: resource.url,
            content: '',
            type: resource === null || resource === void 0 ? void 0 : resource.type,
        });
    });
    const contents = yield Promise.allSettled(contentPromises);
    const resourcesWithContent = [];
    resourcesWithOutContent.forEach((networkScriptItem, index) => {
        var _a, _b;
        let content = (_a = contents[index]) === null || _a === void 0 ? void 0 : _a.value;
        if (networkScriptItem.type === 'document') {
            content = getInlineScriptContent((_b = contents[index]) === null || _b === void 0 ? void 0 : _b.value).toString();
        }
        if (contents[index].status === 'fulfilled') {
            resourcesWithContent.push({
                origin: networkScriptItem.origin,
                content,
                type: networkScriptItem.type,
            });
        }
    });
    return resourcesWithContent;
});
