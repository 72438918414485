import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/*
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * External dependencies.
 */
import { Ellipse, BorderProgressBar, ChevronDown, } from '@google-psat/design-system';
import classNames from 'classnames';
const AccordionHeading = ({ setIsOpen, title, loading, isOpen, featuresText, urlCount = 0, }) => {
    return (_jsxs("div", { onClick: () => setIsOpen(!isOpen), className: "transition-colors flex items-center py-3 cursor-pointer hover:opacity-90 active:opacity-60 hover:bg-[#f5f5f5] hover:dark:bg-[#1d1d1d] rounded-md", children: [_jsxs("span", { className: "flex items-center px-2 relative", children: [_jsx(Ellipse, { className: urlCount ? 'w-6 h-6' : '' }), urlCount !== 0 && (_jsx("span", { className: `${urlCount > 9 ? 'text-xxxs' : 'text-xs'} text-gray dark:text-bright-gray font-semibold absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2`, title: `${urlCount} URL(s) have this known breakage.`, children: urlCount > 9 ? '9+' : urlCount }))] }), _jsxs("p", { className: "flex-1 dark:text-bright-gray font-medium", children: [title, featuresText && (_jsxs("span", { className: "text-gray ml-2 dark:text-bright-gray", children: ["\u2014 ", featuresText] }))] }), _jsx("span", { className: "flex items-center px-2 text-granite-gray dark:text-bright-gray", children: _jsx(ChevronDown, { className: classNames({
                        'rotate-180': isOpen,
                    }) }) }), loading && (_jsx("div", { className: "absolute top-0 left-0 w-full", children: _jsx(BorderProgressBar, {}) }))] }));
};
export default AccordionHeading;
