import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/*
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * External dependencies.
 */
import { PrivacySandboxColoredIcon } from '@google-psat/design-system';
const Header = ({ url, dateTime }) => {
    // @ts-ignore - Global object.
    const isExtension = globalThis?.PSAT_EXTENSION;
    return (_jsxs("div", { className: "flex justify-between gap-3 px-4 py-2 bg-white dark:bg-charleston-green border-b border-hex-gray dark:border-quartz", children: [_jsxs("div", { className: "flex gap-2 items-center", children: [_jsx(PrivacySandboxColoredIcon, { className: "w-[40px] h-[40px]" }), _jsxs("div", { children: [url && _jsx("p", { className: "text-xs mb-[1px]", children: url }), _jsx("p", { className: "text-xs mb-[1px]", children: dateTime })] })] }), _jsx("div", { className: "flex items-center text-cente", children: isExtension ? 'PSAT Extension Analysis' : 'PSAT CLI Analysis' })] }));
};
export default Header;
