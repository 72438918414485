import { jsx as _jsx } from "react/jsx-runtime";
/*
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * External dependencies.
 */
import { useMemo } from 'react';
import { useContextSelector, createContext, } from '@google-psat/common';
/**
 * Internal dependencies.
 */
import reshapeCookies from '../../../utils/reshapeCookies';
const initialState = {
    state: {
        tabCookies: {},
        technologies: [],
        completeJson: null,
        path: '',
        libraryMatches: null,
    },
};
export const Context = createContext(initialState);
export const Provider = ({ cookies, technologies, completeJson, children, path, libraryMatches, }) => {
    const tabCookies = useMemo(() => reshapeCookies(cookies), [cookies]);
    return (_jsx(Context.Provider, { value: {
            state: {
                tabCookies,
                technologies,
                completeJson,
                path,
                libraryMatches,
            },
        }, children: children }));
};
/**
 * Cookie store hook.
 * @param selector Selector function to partially select state.
 * @returns selected part of the state
 */
export function useContentStore(selector = (state) => state) {
    return useContextSelector(Context, selector);
}
