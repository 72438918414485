/*
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const disqusCommentsDOMQuery = () => {
    const matchItems = [];
    const disqusThreadId = document.getElementById('disqus_thread');
    const disqusCommentIframeSrcRegex = /^https:\/\/disqus\.com\/embed\/comments/;
    if (disqusThreadId) {
        const iframes = document.querySelectorAll('iframe');
        iframes.forEach((iframe) => {
            if (iframe.src && disqusCommentIframeSrcRegex.test(iframe.src)) {
                matchItems.push(`iframe[src]: ${iframe.src}`);
            }
        });
        if (matchItems.length) {
            matchItems.push('div[id]: disqus_thread');
        }
    }
    return matchItems;
};
export default disqusCommentsDOMQuery;
