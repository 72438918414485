import { jsx as _jsx } from "react/jsx-runtime";
/*
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * External dependencies.
 */
import { useState, useCallback, useEffect, } from 'react';
import Context, { initialLibraryMatches } from './context';
const LibraryDetectionProvider = ({ children }) => {
    const [libraryMatches, setLibraryMatches] = useState(initialLibraryMatches);
    const [isCurrentTabLoading, setIsCurrentTabLoading] = useState(false); // TODO: Use first/current tab loaded state instead.
    const [isInitialDataUpdated, setIsInitialDataUpdated] = useState(false);
    const [loadedBefore, setLoadedBeforeState] = useState(false);
    const [errorOccured, setErrorOccured] = useState(false);
    const [showLoader, setShowLoader] = useState(true);
    const [tabId, setTabId] = useState(-1);
    useEffect(() => {
        setTabId(chrome.devtools.inspectedWindow.tabId);
        chrome.tabs.get(chrome.devtools.inspectedWindow.tabId, (tab) => {
            if (tab.status) {
                setIsCurrentTabLoading(tab.status === 'loading');
            }
        });
    }, []);
    const onErrorOccuredListener = useCallback(({ frameId, error, tabId: _tabId, }) => {
        if (frameId === 0 &&
            _tabId === chrome.devtools.inspectedWindow.tabId &&
            error !== 'net::ERR_ABORTED') {
            setErrorOccured(true);
        }
        else if (frameId === 0 &&
            _tabId === chrome.devtools.inspectedWindow.tabId &&
            error === 'net::ERR_ABORTED') {
            setIsCurrentTabLoading(false);
        }
    }, []);
    const onCompletedListener = useCallback(({ frameId, frameType, tabId: changingTabId, }) => {
        if (frameId === 0 &&
            frameType === 'outermost_frame' &&
            Number(changingTabId) === Number(tabId)) {
            setIsCurrentTabLoading(false);
            setErrorOccured(false);
        }
    }, [tabId]);
    const onNavigatedListener = useCallback(({ frameId, tabId: _tabId, }) => {
        if (frameId === 0 && Number(tabId) === Number(_tabId)) {
            setLibraryMatches(initialLibraryMatches);
            setIsCurrentTabLoading(true);
            setShowLoader(true);
            setLoadedBeforeState(false);
            setIsInitialDataUpdated(false);
        }
    }, [tabId]);
    useEffect(() => {
        chrome.webNavigation.onCompleted.addListener(onCompletedListener);
        chrome.webNavigation.onErrorOccurred.addListener(onErrorOccuredListener);
        chrome.webNavigation.onBeforeNavigate.addListener(onNavigatedListener);
        return () => {
            chrome.webNavigation.onCompleted.removeListener(onCompletedListener);
            chrome.webNavigation.onBeforeNavigate.removeListener(onNavigatedListener);
            chrome.webNavigation.onErrorOccurred.removeListener(onErrorOccuredListener);
        };
    }, [onErrorOccuredListener, onNavigatedListener, onCompletedListener]);
    return (_jsx(Context.Provider, { value: {
            state: {
                libraryMatches,
                isCurrentTabLoading,
                isInitialDataUpdated,
                loadedBefore,
                showLoader,
                tabId,
                errorOccured,
            },
            actions: {
                setLibraryMatches,
                setIsCurrentTabLoading,
                setIsInitialDataUpdated,
                setLoadedBeforeState,
                setShowLoader,
                setErrorOccured,
            },
        }, children: children }));
};
export default LibraryDetectionProvider;
