/*
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { I18n } from '@google-psat/i18n';

//For source see https://source.chromium.org/chromium/chromium/src/+/main:third_party/devtools-frontend/src/front_end/core/sdk/NetworkRequest.ts
const CookieExemptionReason = {
  UserSetting: () => I18n.getMessage('exemptionReasonUserSetting'),
  TPCDMetadata: () => I18n.getMessage('exemptionReasonTPCDMetadata'),
  TPCDDeprecationTrial: () =>
    I18n.getMessage('exemptionReasonTPCDDeprecationTrial'),
  TPCDHeuristics: () => I18n.getMessage('exemptionReasonTPCDHeuristics'),
  EnterprisePolicy: () => I18n.getMessage('exemptionReasonEnterprisePolicy'),
  StorageAccessAPI: () => I18n.getMessage('exemptionReasonStorageAccessAPI'),
  TopLevelStorageAccessAPI: () =>
    I18n.getMessage('exemptionReasonTopLevelStorageAccessAPI'),
  CorsOptIn: () => I18n.getMessage('exemptionReasonCorsOptIn'),
};

export default CookieExemptionReason;
