import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/*
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * External dependencies.
 */
import React, { useState } from 'react';
import classNames from 'classnames';
/**
 * Internal dependencies.
 */
import AccordionHeading from './accordionHeading';
import AccordionContent from './accordionContent';
const Accordion = ({ children, title, isLoading = false, featuresText = '', urlCount, }) => {
    const [isOpen, setIsOpen] = useState(false);
    const parentClass = classNames({
        relative: true,
    });
    return (_jsxs("div", { className: parentClass, "data-testid": "library-detection-accordion", children: [_jsx(AccordionHeading, { setIsOpen: setIsOpen, isOpen: isOpen, loading: isLoading, title: title, featuresText: featuresText, urlCount: urlCount }), _jsx(AccordionContent, { isOpen: isOpen, children: children })] }));
};
export default Accordion;
