import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/*
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * External dependencies.
 */
import React, { useEffect, useMemo, useState } from 'react';
import { Resizable } from 're-resizable';
import { noop, } from '@google-psat/common';
import { useSidebar, Sidebar, SIDEBAR_ITEMS_KEYS, File, FileWhite, } from '@google-psat/design-system';
/**
 * Internal dependencies.
 */
import SiteMapCookiesWithIssues from './sitemapCookiesWithIssues';
import reshapeCookies from '../utils/reshapeCookies';
import CookiesTab from './cookies';
const Layout = ({ landingPageCookies, completeJson, sidebarData, setSidebarData, path, libraryMatches, }) => {
    const [sites, setSites] = useState([]);
    useEffect(() => {
        const _sites = new Set();
        completeJson?.forEach(({ pageUrl }) => {
            _sites.add(pageUrl);
        });
        setSites(Array.from(_sites));
    }, [completeJson]);
    const reshapedCookies = useMemo(() => reshapeCookies(landingPageCookies), [landingPageCookies]);
    const cookiesWithIssues = useMemo(() => Object.fromEntries(Object.entries(reshapedCookies).filter(([, cookie]) => cookie.isBlocked || cookie.blockedReasons?.length)), [reshapedCookies]);
    const { activePanel, selectedItemKey, updateSelectedItemKey, isKeySelected } = useSidebar(({ state, actions }) => ({
        activePanel: state.activePanel,
        selectedItemKey: state.selectedItemKey,
        updateSelectedItemKey: actions.updateSelectedItemKey,
        isKeySelected: actions.isKeySelected,
    }));
    const { Element: PanelElement, props } = activePanel.panel;
    const { query } = activePanel;
    const clearQuery = useMemo(() => {
        return query ? activePanel.clearQuery : noop;
    }, [query, activePanel.clearQuery]);
    const doesSiteHaveCookies = useMemo(() => {
        const store = {};
        completeJson?.forEach((data) => {
            store[data.pageUrl] = Object.entries(data.cookieData).reduce((acc, [, frameData]) => {
                return acc || Object.keys(frameData.frameCookies || {}).length > 0;
            }, false);
        });
        return store;
    }, [completeJson]);
    const tabFrames = useMemo(() => sites.reduce((acc, site) => {
        acc[site] = {};
        return acc;
    }, {}), [sites]);
    useEffect(() => {
        setSidebarData((prev) => {
            const _data = { ...prev };
            _data[SIDEBAR_ITEMS_KEYS.COOKIES].panel = {
                Element: CookiesTab,
                props: {
                    tabCookies: reshapedCookies,
                    tabFrames,
                    completeJson,
                    path,
                    libraryMatches,
                    query,
                    clearQuery,
                },
            };
            _data[SIDEBAR_ITEMS_KEYS.COOKIES].children = sites.reduce((acc, site) => {
                acc[site] = {
                    title: site,
                    panel: {
                        Element: CookiesTab,
                        props: {
                            selectedSite: site,
                            tabCookies: reshapedCookies,
                            tabFrames,
                            completeJson,
                            path,
                            libraryMatches,
                            query,
                            clearQuery,
                        },
                    },
                    children: {},
                    icon: {
                        //@ts-ignore
                        Element: File,
                    },
                    selectedIcon: {
                        //@ts-ignore
                        Element: FileWhite,
                    },
                    isBlurred: doesSiteHaveCookies[site] === false,
                };
                return acc;
            }, {});
            _data[SIDEBAR_ITEMS_KEYS.COOKIES_WITH_ISSUES].panel = {
                Element: SiteMapCookiesWithIssues,
                props: {
                    cookies: Object.values(cookiesWithIssues),
                    path,
                },
            };
            return _data;
        });
    }, [
        clearQuery,
        completeJson,
        cookiesWithIssues,
        doesSiteHaveCookies,
        libraryMatches,
        path,
        query,
        reshapedCookies,
        setSidebarData,
        sites,
        tabFrames,
    ]);
    useEffect(() => {
        if (selectedItemKey === null && Object.keys(sidebarData).length > 0) {
            updateSelectedItemKey(SIDEBAR_ITEMS_KEYS.COOKIES);
        }
    }, [isKeySelected, selectedItemKey, sidebarData, updateSelectedItemKey]);
    return (_jsxs("div", { className: "w-full h-full flex dark:bg-raisin-black text-raisin-black dark:text-bright-gray", children: [_jsx(Resizable, { defaultSize: { width: '200px', height: '100%' }, minWidth: '150px', maxWidth: '50%', enable: {
                    right: true,
                }, children: _jsx(Sidebar, {}) }), _jsx("div", { className: "flex-1 max-h-screen overflow-auto", children: PanelElement && _jsx(PanelElement, { ...props }) })] }));
};
export default Layout;
