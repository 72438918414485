import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/*
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * External dependencies.
 */
import React, { memo, useEffect, useMemo, useState } from 'react';
import { getCookieKey, noop, } from '@google-psat/common';
import { I18n } from '@google-psat/i18n';
import { Resizable } from 're-resizable';
import { ChipsBar, FiltersSidebar, useGlobalFiltering, FilterIcon, } from '@google-psat/design-system';
/**
 * Internal dependencies.
 */
import Landing from './cookieLanding/landing';
import Header from '../../../../header';
const AssembledCookiesLanding = ({ tabCookies, tabFrames, setAppliedFilters, downloadReport, libraryMatches, libraryMatchesUrlCount, menuBarScrollContainerId = 'dashboard-layout-container', query = '', clearQuery = noop, url, }) => {
    const cookies = useMemo(() => Object.values(tabCookies || {}), [tabCookies]);
    const filterOutput = useGlobalFiltering(cookies, query, clearQuery);
    // @ts-ignore Using global variable.
    const { dateTime } = globalThis?.PSAT_DATA || '';
    const cookiesByKey = useMemo(() => {
        return (filterOutput.filteredData.reduce((acc, cookie) => {
            const cookieKey = getCookieKey(cookie.parsedCookie);
            if (!cookieKey) {
                return acc;
            }
            acc[cookieKey] = cookie;
            return acc;
        }, {}) || {});
    }, [filterOutput.filteredData]);
    const cookiesWithIssues = useMemo(() => Object.fromEntries(Object.entries(cookiesByKey).filter(([, cookie]) => cookie.isBlocked)), [cookiesByKey]);
    useEffect(() => {
        setAppliedFilters(filterOutput.selectedFilters);
    }, [filterOutput.selectedFilters, setAppliedFilters]);
    const [showFilterSidebar, setShowFilterSidebar] = useState(false);
    // @ts-ignore - Global object.
    const showHeader = globalThis?.PSAT_DATA?.showHeader;
    return (_jsxs("div", { className: "h-full flex flex-col", children: [showHeader && _jsx(Header, { url: url, dateTime: dateTime }), _jsxs("div", { className: "flex justify-center items-center flex-1 border-b border-gray-300 dark:border-quartz bg-anti-flash-white dark:bg-raisin-black", children: [_jsx("button", { className: "w-3 h-3 m-1 pl-1", onClick: () => setShowFilterSidebar(!showFilterSidebar), title: I18n.getMessage('openFilterOptions'), children: _jsx(FilterIcon, { className: showFilterSidebar
                                ? 'text-royal-blue dark:text-medium-persian-blue'
                                : 'text-mischka' }) }), _jsx(ChipsBar, { selectedFilters: filterOutput.selectedFilters, resetFilters: filterOutput.resetFilters, toggleFilterSelection: filterOutput.toggleFilterSelection })] }), _jsxs("div", { className: "flex grow-0", style: {
                    height: 'calc(100% - 26px)',
                }, children: [showFilterSidebar && (_jsx(Resizable, { minWidth: "100px", maxWidth: "50%", enable: {
                            right: true,
                        }, className: "border border-r border-gray-300 dark:border-quartz", children: _jsx(FiltersSidebar, { filters: filterOutput.filters, toggleFilterSelection: filterOutput.toggleFilterSelection, toggleSelectAllFilter: filterOutput.toggleSelectAllFilter, isSelectAllFilterSelected: filterOutput.isSelectAllFilterSelected }) })), _jsx("div", { className: "flex-1 overflow-auto h-full", id: menuBarScrollContainerId, children: _jsx(Landing, { tabCookies: cookiesByKey, tabFrames: tabFrames, cookiesWithIssues: cookiesWithIssues, downloadReport: downloadReport, libraryMatches: libraryMatches, libraryMatchesUrlCount: libraryMatchesUrlCount, menuBarScrollContainerId: menuBarScrollContainerId }) })] })] }));
};
export default memo(AssembledCookiesLanding);
