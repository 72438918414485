/*
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * External dependencies.
 */
import React from 'react';
/**
 * Internal dependencies
 */
import { COLOR_MAP } from '../../theme/colors';

interface CircleEmptyProps {
  color?: string;
}

const CircleEmpty = ({
  color = COLOR_MAP.mediumGray.color,
}: CircleEmptyProps) => (
  <div
    className="w-2.5 h-2.5 flex-shrink-0 rounded-full border-2 border-solid"
    style={{ borderColor: color }}
  />
);

export default CircleEmpty;
